import React from 'react';
import {ConfigProvider, Form, message} from 'antd';

import Api from "./config/Api";
import {BrowserRouter, Route} from 'react-router-dom';
import Home from "./pages/Home";
import InfoUser from "./pages/InfoUser";
import AppContainer from "./AppContainer";

import 'moment/locale/tr';
import locale from 'antd/lib/locale/tr_TR';

let self = null;

class App extends React.Component {

    constructor(props) {
        super(props);
        self = this;
    }

    componentDidMount() {
        self.tokenCheckRefresh();
    }

    tokenCheckRefresh() {
        self.setState({spinning: true});
        Api.tokenCheck().then(function () {
            self.setState({spinning: false});
        }).catch(function (error) {

            message.error('Üzgünüm ancak işlem yapabilmek için yetki alamadık 10 saniye sonra tekrar deneyeceğiz.');

            setTimeout(function () {
                message.warning('Tekrar deniyoruz...');
                self.tokenCheckRefresh();
            }, 10000);

            self.setState({spinning: false});
        });
    }


    render() {

        return (
            <ConfigProvider locale={locale}>
            <BrowserRouter>
                <AppContainer>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/:smsCode' component={InfoUser}/>
                </AppContainer>
            </BrowserRouter>
            </ConfigProvider>
        );
    }
}

export default Form.create({name: 'formsave'})(App);
