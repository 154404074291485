import _ from 'lodash';

export function telephoneClear(landPhone) {
    landPhone = _.replace(landPhone, / /g, '');
    landPhone = _.replace(landPhone, '(', '');
    landPhone = _.replace(landPhone, ')', '');
    landPhone = _.replace(landPhone, '+9', '');
    if (landPhone.length == 11) {
        landPhone = landPhone.substring(1);
    }
    return landPhone;
}

export function errorMessage(data) {
    data = data.response ? data.response.data : data;
    data = data.data ? data.data : data;
    let message = '';
    if (data.Result) {
        message += data.Result;
    } else if (data.message) {
        message += data.message;
    } else if (data.Message) {
        message += data.Message;
    } else if (data.message == null && data.code) {
        message += data.code;
    } else if (data) {
        message += data;
    } else {
        message = '<li>Bir hata oluştu lütfen daha sonra tekrar dene.</li>';
    }

    return message;

}


export function pageChange(thisSelf, changePage) {
    const {history} = thisSelf.props;
    if (changePage && history) {
        history.push(changePage);
    } else {
        window.location.href = changePage;
    }
}
