import {Button, Checkbox, DatePicker, Form, Input, message, Modal, Result, Select, Spin} from "antd";
import MaskedInput from "antd-mask-input";
import React from "react";
import Api from "../config/Api";
import {errorMessage, telephoneClear} from "../helpers/GeneralHelpers";
import moment from "moment";
import {ShoppingCartOutlined} from '@ant-design/icons';
import {ModalK, ModalM} from "./Modal";

let self = null;

class Home extends React.Component {
    constructor(props) {
        super(props);

        self = this;

        this.state = {
            spinning: false,
            activities: false,
            activitiesId: false,
            otpRecordId: false,
            step2form: {},
            skinType: [],
            step: 2,
            cities: [],
            modal: false,
            modal1: false,
            checked1: false,
            checked: false,
            time: {},
            seconds: 180,
            reSendButtonDisable: true,
            timer: 0
        }
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    componentDidMount() {
        this.setState({spinning: true}, () => {
            Api.cities().then((response) => {
                Api.activities().then((response2) => {
                    Api.skinType().then((response3) => {
                        this.setState({
                            spinning: false,
                            activities: response2.data,
                            cities: response.data,
                            activitiesId: response2.data[0] ? response2.data[0].activitiesId : false,
                            skinType: response3.data.skinTypeList
                        });

                    }).catch((error) => {
                        this.setState({spinning: false}, () => {
                            const message2 = errorMessage(error);
                            message.error(message2);
                        });
                    });
                }).catch((error) => {
                    this.setState({spinning: false}, () => {
                        const message2 = errorMessage(error);
                        message.error(message2);
                    });
                });
            }).catch((error) => {
                this.setState({spinning: false}, () => {
                    const message2 = errorMessage(error);
                    message.error(message2);
                });
            });
        });

        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({time: timeLeftVar});
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let time = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return time;
    }

    startTimer() {
        if (this.state.timer === 0 && this.state.seconds > 0) {
            this.state.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
        if (seconds === 0) {
            clearInterval(this.state.timer);
            this.setState({
                reSendButtonDisable: false,
                timer: 0
            });
        }
    }

    getCounties(id) {
        this.setState({spinning: true}, () => {
            Api.counties(id).then((response) => {
                this.setState({
                    spinning: false,
                    counties: response.data,
                });
            }).catch((error) => {
                self.setState({spinning: false}, () => {
                    const message2 = errorMessage(error);
                    message.error(message2);
                });
            });
        });
    }

    submitStep2 = e => {
        if (e) {
            e.preventDefault();
        }
        self.props.form.validateFields((err, values) => {

            values.mobileNumber = telephoneClear(values.mobileNumber);
            values.birthdate = values.birthdate ? values.birthdate.toISOString() : '';
            const data = values;
            const skinType = [];
            skinType.push(data.skinType)
            data.skinType = skinType;
            if (!err) {
                self.setState({spinning: true, step2form: data}, () => {
                    Api.sendotpsmsforactivities(data).then((response) => {
                        this.setState({
                            step: 3,
                            spinning: false,
                            otpRecordId: response.data.otpRecordId,
                        });
                    }).catch((error) => {
                        this.setState({spinning: false}, () => {
                            const message2 = errorMessage(error);
                            message.error(message2);
                        });
                    });
                });
            }
        });
    };

    submitStep3 = e => {
        e.preventDefault();
        const {otpRecordId, activitiesId} = self.state;
        self.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({spinning: true}, () => {
                    Api.verifyotpforactivities(otpRecordId, values.smsCode, activitiesId).then(function (response) {
                        self.setState({
                            spinning: false,
                            step: 4
                        }, () => {
                            setTimeout(() => {
                                window.location.href = 'https://shop.naosstars.com/';
                            }, 2000);
                        });
                    }).catch((error) => {
                        this.setState({spinning: false}, () => {
                            const message2 = errorMessage(error);
                            message.error(message2);
                        });
                    });
                });
            }
        });
    };

    resendSmsCode() {
        const {activitiesId, step2form} = this.state;
        Modal.confirm({
            title: 'Lütfen Dikkat!',
            content: 'Tekrar sms göndermek istediğinize emin misin?',
            okText: 'Evet',
            okType: 'danger',
            cancelText: 'Vazgeç',
            onOk() {
                self.setState({spinning: true}, () => {
                    Api.sendotpsmsforactivities(step2form).then((response) => {
                        self.setState({
                            spinning: false,
                            otpRecordId: response.data.otpRecordId,
                            seconds: 180,
                            timer: 0,
                            time: {},
                            reSendButtonDisable: true
                        }, () => {
                            message.success('Sms tekrar gönderim başarılı.');
                            self.startTimer();
                        });
                    }).catch((error) => {
                        self.setState({spinning: false}, () => {
                            const message2 = errorMessage(error);
                            message.error(message2);
                        });
                    });
                });
            },
            onCancel() {

            },
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {step, spinning, activities, step2form, cities, counties, skinType, checked, checked1} = self.state;


        const minDate = moment().subtract('1', 'years').format('YYYY-MM-DD');

        return (
            <>
                <Modal
                    title="Gizlilik ve Kisisel Verilerin Korunması ve Çerez Politikası"
                    style={{top: 20}}
                    visible={this.state.modal}
                    onCancel={() => this.setState({modal: false})}
                    footer={[
                        <Button key="submit" type="primary" onClick={() => this.setState({modal: false})}>
                            Tamam
                        </Button>,
                    ]}
                    width={900}
                >
                    <ModalK/>
                </Modal>
                <Modal
                    title="Gizlilik ve Kisisel Verilerin Korunması ve Çerez Politikası"
                    style={{top: 20}}
                    visible={this.state.modal1}
                    onCancel={() => this.setState({modal1: false})}
                    footer={[
                        <Button key="submit" type="primary" onClick={() => this.setState({modal1: false})}>
                            Tamam
                        </Button>,
                    ]}
                    width={900}
                >
                    <ModalM/>
                </Modal>
                <div className={'h-100'}>
                    <Spin size={'large'} spinning={spinning}>
                        <div className={'logo'}>
                            <div className={'f1'}><img src={require('../../src/images/logo-white.png')} className={'fleft'}/></div>
                            <a href="https://shop.naosstars.com/" target="_blank" className="button1 go-shop">
                                <ShoppingCartOutlined/> Alışverişe Başla
                            </a>
                        </div>
                        {
                            (step == 1 || step == 2) && (
                                <div className={'form1'}>
                                    <ul className={'ol'}>
                                        <li>@biodermaturkiye hesabını takip etmeyi unutma!</li>
                                        <li>Formu eksiksiz doldur tanışma kiti kazanma fırsatını kaçırma!</li>
                                    </ul>

                                    <Form labelCol={{span: 6}} wrapperCol={{span: 18}} onSubmit={this.submitStep2}>

                                        <Form.Item label={'Etkinlik Seçimi'}>
                                            {getFieldDecorator('activitiesId', {
                                                rules: [{required: true, message: 'Lütfen etkinlik giriniz'}],
                                                initialValue: activities[0] ? activities[0].activitiesId : false
                                            })(
                                                <Select placeholder="Etkinlik Seçin" size={'large'}
                                                        onChange={(e) => this.setState({activitiesId: e})}>
                                                    {
                                                        activities && activities.map((v, i) => {
                                                            return (
                                                                <Select.Option key={i} value={v.activitiesId}>{v.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Ad">
                                            {getFieldDecorator('name', {
                                                rules: [{required: true, message: 'Lütfen ad giriniz'}],
                                            })(<Input size={'large'}/>)}
                                        </Form.Item>
                                        <Form.Item label="Soyad">
                                            {getFieldDecorator('surname', {
                                                rules: [{required: true, message: 'Lütfen soyad giriniz'}],
                                            })(<Input size={'large'}/>)}
                                        </Form.Item>
                                        <Form.Item label="E-Posta Adresi">
                                            {getFieldDecorator('email', {
                                                rules: [{required: true, type: 'email', message: 'Lütfen doğru e-posta giriniz'}],
                                            })(<Input size={'large'}/>)}
                                        </Form.Item>
                                        <Form.Item label="Telefon Numarası">
                                            {getFieldDecorator('mobileNumber', {
                                                rules: [{required: true, message: 'Lütfen telefon giriniz'}],
                                            })(<MaskedInput mask={'+90(111) 111 11 11'} size={'large'}/>)}
                                        </Form.Item>
                                        <Form.Item label={'Şehir'}>
                                            {getFieldDecorator('cityId', {
                                                rules: [{required: true, message: 'Lütfen Şehir seçin'}],
                                            })(
                                                <Select placeholder="Şehir Seçin" size={'large'} onChange={(e) => this.getCounties(e)}>
                                                    {
                                                        cities && cities.map((v, i) => {
                                                            return (
                                                                <Select.Option key={i} value={v.cityId}>{v.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={'İlçe'}>
                                            {getFieldDecorator('countyId', {
                                                rules: [{required: true, message: 'Lütfen İlçe seçin'}],
                                            })(
                                                <Select placeholder="İlçe Seçin" size={'large'}>
                                                    {
                                                        counties && counties.map((v, i) => {
                                                            return (
                                                                <Select.Option key={i} value={v.countyId}>{v.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Açık Adres">
                                            {getFieldDecorator('address', {
                                                rules: [{required: true, min: 10, message: 'Lütfen açık adresinizi giriniz'}],
                                            })(<Input.TextArea size={'large'}/>)}
                                        </Form.Item>

                                        <Form.Item label="Instagram Kullanıcı Adı">
                                            {getFieldDecorator('instagramname', {
                                                rules: [{required: true, message: 'Lütfen instagram ismini giriniz'}],
                                            })(<Input size={'large'}/>)}
                                        </Form.Item>

                                        <Form.Item label="Doğum Tarihi">
                                            {getFieldDecorator('birthdate', {
                                                rules: [{required: true, message: 'Lütfen doğum tarihi seçiniz'}],
                                                placeholder: 'Doğum Tarihi'
                                            })(
                                                <DatePicker
                                                    placeholder={'Doğum tarihi seçiniz'}
                                                    size={'large'}
                                                    allowClear={true}
                                                    disabledDate={d => !d || d.isAfter(minDate) || d.isSameOrBefore("1960-01-01")}
                                                    defaultPickerValue={moment(minDate)}
                                                    format="DD-MM-YYYY"
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Cilt Tipi">
                                            {
                                                getFieldDecorator('skinType', {
                                                    rules: [{required: true, message: 'Lütfen cilt tipi seçiniz'}],
                                                })(
                                                    <Select placeholder={'Cilt Tipi'}>
                                                        {
                                                            skinType.map((v, i) => (
                                                                <Select.Option value={v.skinTypeId} key={i}>{v.name}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </Form.Item>

                                        <Form.Item wrapperCol={{span: 19, offset: 5}} style={{marginBottom: 0}}>
                                            {getFieldDecorator('kvkk', {
                                                valuePropName: 'checked',
                                                initialValue: false,
                                                rules: [{
                                                    required: true,
                                                    transform: value => (value || undefined),
                                                    type: 'boolean',
                                                    message: 'Bu alanı kabul etmeniz gerekiyor.',
                                                }],
                                            })(
                                                <Checkbox onChange={() => this.setState({checked: !checked})}>
                                                    <strong onClick={() => this.setState({modal: true})}>Gizlilik ve Kişisel Veri Koruma
                                                        Politikası</strong> çerçevesinde tarafıma, pazarlama ve iletişim amaçlı ileti gönderilmesini
                                                    izin veriyorum.
                                                </Checkbox>
                                            )}
                                        </Form.Item>
                                        <Form.Item wrapperCol={{span: 19, offset: 5}} style={{marginBottom: 0}}>
                                            {getFieldDecorator('kvkk2', {
                                                valuePropName: 'checked',
                                                initialValue: false,
                                                rules: [{
                                                    required: true,
                                                    transform: value => (value || undefined),
                                                    type: 'boolean',
                                                    message: 'Bu alanı kabul etmeniz gerekiyor.',
                                                }],
                                            })(
                                                <Checkbox onChange={() => this.setState({checked1: !checked1})}>
                                                    <strong onClick={() => this.setState({modal: true})}>Gizlilik ve Kişisel Veri Koruma
                                                        Politikası</strong>’na uygun olarak Kişisel Verilerimin işlenmesine onay veriyorum.
                                                </Checkbox>
                                            )}
                                        </Form.Item>

                                        <Form.Item wrapperCol={{span: 19, offset: 5}}>
                                            <label><strong onClick={() => this.setState({modal1: true})}>Kişisel Verilerin İşlenmesine Dair Aydınlatma
                                                Metnini</strong> okudum.</label>
                                        </Form.Item>

                                        <Form.Item wrapperCol={{span: 24, offset: 0}} className={'mr-auto'}>
                                            <Button type={'ghost'} size={'large'} style={{marginRight: 8}} onClick={() => self.setState({step: 1})}>
                                                Geri Dön
                                            </Button>
                                            <Button type="primary" htmlType="submit" size={'large'} onClick={this.startTimer}>
                                                Devam Et
                                            </Button>

                                        </Form.Item>
                                    </Form>

                                    <ul className={'ol ol2'}>
                                        <li>Sensibio Hassas Cilt Tanışma Kiti stoklarla sınırlıdır.</li>
                                    </ul>

                                </div>
                            )
                        }

                        {
                            step == 3 && (
                                <Form layout={"horizontal"} onSubmit={this.submitStep3} className={'form1'}>
                                    <p className={'white'}>
                                        Lütfen <strong>"{step2form.mobileNumber}"</strong> numaralı telefona gönderilen doğrulama kodunu aşağıya
                                        giriniz.
                                    </p>
                                    <Form.Item label="Sms Kodu">
                                        {getFieldDecorator('smsCode', {
                                            rules: [{required: true, min: 4, message: 'Lütfen sms kodunu giriniz.'}],
                                        })(<Input size={'large'}/>)}
                                    </Form.Item>
                                    <Form.Item className={'text-center'}>
                                        <Button type="primary" htmlType="submit" size={'large'}>
                                            Doğrula
                                        </Button>
                                        <a style={{padding: 10, fontSize: 16, fontWeight: "bold", color: "red"}}> {this.state.time.m}:{this.state.time.s}</a>
                                        <Button type={"danger"} htmlType={'button'} size={'large'} style={{marginLeft: 8}}
                                                onClick={() => this.resendSmsCode()}  disabled={this.state.reSendButtonDisable}>
                                            Tekrar Gönder
                                        </Button>

                                        <Button type={'ghost'} size={'large'} htmlType={'button'} style={{marginLeft: 8}}
                                                onClick={() => this.setState({step: 2})}>
                                            Yeni Giriş
                                        </Button>

                                    </Form.Item>
                                </Form>
                            )
                        }

                        {
                            step == 4 && (
                                <div className={'form1'}>
                                    <Result
                                        status="success"
                                        title="Kaydınız tamamlanmıştır. shop.naosstars.com’a yönlendiriyoruz."
                                    />
                                </div>
                            )
                        }

                    </Spin>
                </div>
            </>
        )
    }
}

export default Form.create(
    {
        name: 'home'
    }
)(Home);
