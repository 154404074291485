import axios from "axios/index";

const Api = {

    apiUrl: function () {
        const url = window.location.host;
        const testApiUrl = 'https://naosdigitalapi-test.azurewebsites.net/';
        const ApiUrl = 'https://naosdigitalapi.azurewebsites.net/';

        if (url.indexOf('localhost') != -1 || url.indexOf('127.0.0.1') != -1 || url.indexOf('-test') != -1) {
            return testApiUrl;

        } else {
            return ApiUrl;
        }
    },

    tokenCheck: function () {
        return new Promise((resolve, reject) => {
            const dt = new Date();
            const timestamp = Math.floor(dt.getTime() / 1000);
            const userToken = localStorage.getItem('userToken');
            const json = userToken ? JSON.parse(userToken) : false;
            let newTokenGet = false;
            if (json) {
                let expires_start = json.expires_start - 1000;
                if (timestamp >= expires_start) {
                    newTokenGet = true;
                }
            } else {
                newTokenGet = true;
            }
            if (newTokenGet) {
                Api.getToken().then(response => {
                    if (response.data.expires_in) {
                        const dt = new Date();
                        const timestamp = Math.floor(dt.getTime() / 1000);
                        const res = response.data;
                        res.expires_start = parseInt(timestamp) + parseInt(response.data.expires_in);
                        localStorage.setItem('userToken', JSON.stringify(res));
                        resolve(res);
                    }
                }).catch(function (error) {
                    reject(error);
                });
            } else {
                resolve(json);
            }
        });
    },

    //Auth start
    getToken: function () {
        const username = 'naosforce';
        const password = 'naosforce2019!';
        const data = {
            "grant_type": "password",
            "username": username,
            "password": password
        };
        return axios.post(Api.apiUrl() + 'Api/getToken', data);
    },

    getHeaderToken() {
        return new Promise((resolve, reject) => {
            Api.tokenCheck().then(function (json) {
                const result = {
                    'Content-Type': 'application/json',
                    'Authorization': json.token_type + ' '.concat(json.access_token)
                };
                resolve(result);
            }).catch(error => reject(error));
        });
    },

    activities() {
        return new Promise((resolve, reject) => {
            Api.getHeaderToken().then(function (headers) {
                axios.get(Api.apiUrl() + 'api/activities', {headers: headers}).then(r => resolve(r)).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    },

    cities() {
        return new Promise((resolve, reject) => {
            Api.getHeaderToken().then(function (headers) {
                axios.get(Api.apiUrl() + 'api/common/cities', {headers: headers}).then(r => resolve(r)).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    },

    counties(id) {
        return new Promise((resolve, reject) => {
            Api.getHeaderToken().then(function (headers) {
                axios.get(Api.apiUrl() + 'api/common/city/'+id+'/counties', {headers: headers}).then(r => resolve(r)).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    },

    skinType() {
        return new Promise((resolve, reject) => {
            Api.getHeaderToken().then(function (headers) {
                axios.get(Api.apiUrl() + 'api/common/skinType', {headers: headers}).then(r => resolve(r)).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    },

    sendotpsmsforactivities(data) {
        return new Promise((resolve, reject) => {
            Api.getHeaderToken().then(function (headers) {
                axios.post(Api.apiUrl() + 'api/sendotpsmsforactivities', data, {headers: headers}).then(r => resolve(r)).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    },

    getcustomerbyotpcode(smsCode) {
        return new Promise((resolve, reject) => {
            Api.getHeaderToken().then(function (headers) {
                axios.get(Api.apiUrl() + 'api/getcustomerbyotpcode/' + smsCode, {headers: headers}).then(r => resolve(r)).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    },

    verifyotpforactivities(otpRecordId, smsCode,activitiesId) {
        return new Promise((resolve, reject) => {
            let data = {
                "otpRecordId": otpRecordId,
                "smsCode": smsCode,
                "activitiesId": activitiesId
            };
            Api.getHeaderToken().then(function (headers) {
                axios.put(Api.apiUrl() + 'api/verifyotpforactivities', data, {headers: headers}).then(r => resolve(r)).catch(e => reject(e));
            }).catch(e => reject(e));
        });
    },
};

export default Api;
