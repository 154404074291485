import React from "react";

export function ModalK() {

    return (
        <>
            <p><strong>Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası&rsquo;nın Amacı</strong></p>
            <p>İşbu Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası;</p>
            <p>1. Maslak Mah. Sanatkarlar Sok. No:2 D Blok Kat: 2/13 Maslak / Sarıyer/ İstanbul adresinde mukim NAOS İSTANBUL KOZMETİK SANAYİ VE TİCARET LİMİTED ŞTİ. (&ldquo;NAOS&rdquo; ya da &ldquo;Şirket&rdquo;)&rsquo;in; işbu internet sitesi, mobil web, mobil uygulama siteler aracılığıyla ve sair yollarla ne t&uuml;r kişisel veriler topladığını,</p>
            <p>2. Bu kişisel verilerin nasıl ve ne ama&ccedil;la kullanıldığını,</p>

            <p>3. Şirketimiz&rsquo;in bu kişisel verileri kimlerle paylaşabileceğini,</p>

            <p>4. Şirketimiz&rsquo;in internet sitesi, mobil web siteleri, mobil uygulama ve sair yollarla topladığı kişisel veriler ile ilgili olarak kullanıcılarının/&uuml;yelerinin/m&uuml;şterilerinin haklarının neler olduğunu ve bu hakları nasıl kullanabileceklerini,</p>

            <p>5. &Ccedil;erez&rsquo;ler (Cookie) hakkında bilgilendirmeyi,</p>

            <p>6. Ticari elektronik ileti alma konusunda bilgilendirmeyi i&ccedil;ermektedir.</p>

            <p>Kişisel Verilerin Korunması Hakkında Bilgilendirme</p>

            <p>6698 sayılı Kişisel Verilerin Korunması Kanunu [&quot;KVKK&quot;] uyarınca; aşağıda belirtilen işleme amacı ile bağlantılı ve sınırlı olacak şekilde kullanıcıların/&uuml;yelerin/m&uuml;şterilerin [&quot;Kullanıcı/&Uuml;ye] Şirketimiz ile paylaşmış olduğu işbu Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası&rsquo;nda belirtilen kişisel verilerinin, işlenmelerini gerektiren ama&ccedil; &ccedil;er&ccedil;evesinde kaydedilecek, depolanacak, muhafaza edilecek,
                değiştirilecek, yeniden d&uuml;zenlenecek, kanunen bu kişisel verileri talep etmeye yetkili olan kurumlar ile paylaşılacak, KVKK&rsquo;nın &ouml;ng&ouml;rd&uuml;ğ&uuml; hallerde ve koşullarda, işbirliği i&ccedil;inde olduğumuz yurt i&ccedil;i veya yurt dışında ki &uuml;&ccedil;&uuml;nc&uuml; kişilere aktarılabilecek, devredilebilecek, elde edilebilir hale getirilecek, sınıflandırılabilecek ve KVKK&rsquo;da sayılan sair şekillerde işlenebilecek olduğunu bildiririz.</p>

            <p><strong>İşlenen Kişisel Veriler, Kişisel Veri İşleme Amacı ve Dayanağı</strong></p>

            <p>Kullanıcı/&Uuml;ye&rsquo;ye ait ad, soyadı, doğum tarihi, cep telefonu numarası, sabit telefon numarası, e-posta adresi, adres, sayfaya ilişkin trafik bilgileri; sosyal medya hesaplarıyla bağlanılması durumunda Kullanıcı/&Uuml;ye&rsquo;nin o kanallar aracılığıyla paylaşılmasına onay verdiği bilgiler gibi Kullanıcı/&Uuml;ye&rsquo;yi doğrudan veya dolaylı olarak tanımlamaya y&ouml;nelik kişisel veriler [&quot;Kişisel Veriler&quot;], Kullanıcı/&Uuml;ye&rsquo;ye daha iyi hizmet
                verebilmek, Kullanıcı/&Uuml;ye ile s&ouml;zleşme akdetmek, Kullanıcı/&Uuml;ye&rsquo;ye verilen hizmetin devamı ve iyileştirilmesi, satış, pazarlama ve ticari iletişim yapılabilmesi, satış sonrası hizmetler, varsa abonelik s&uuml;reci ve buna bağlı operasyonel faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi/devam etmesi, istatistiki &ccedil;alışmalar ve pazar araştırması yapılabilmesi gibi ama&ccedil;larla işlenebilmektedir. S&ouml;z konusu Kişisel Veriler&rsquo;in diğer işlenme
                ama&ccedil;ları, 5651 Sayılı İnternet Ortamında Yapılan Yayınların D&uuml;zenlenmesi ve Bu Yayınlar Yoluyla İşlenen Su&ccedil;larla M&uuml;cadele Edilmesi Hakkında Kanun ve ilgili ikincil mevzuat, 6563 Sayılı Elektronik Ticaretin D&uuml;zenlenmesi Hakkında Kanun ve ilgili ikincil mevzuat, 6112 Sayılı Radyo ve Televizyonların Kuruluş ve Yayın Hizmetleri Hakkında Kanun başta olmak &uuml;zere Radyo Televizyon &Uuml;st Kurulu mevzuatı, 5846 Sayılı Fikir ve Sanat Eserleri Kanunu, 5237
                Sayılı T&uuml;rk Ceza Kanunu ilgili diğer yasal d&uuml;zenlemeler uyarınca Kullanıcı/&Uuml;ye&rsquo;ye ait Kişisel Veriler&rsquo;i işlemek; elektronik veya kağıt ortamında işleme amacı doğrultusunda t&uuml;m kayıt ve belgeleri d&uuml;zenlemek; ilgili d&uuml;zenleyici kurumlar ve diğer otoritelerce &ouml;ng&ouml;r&uuml;len bilgi saklama, raporlama, bilgilendirme ve bilgi paylaşma y&uuml;k&uuml;ml&uuml;l&uuml;klerine uymaktır. Kişisel Veriler; Kullanıcı/&Uuml;ye&rsquo;ye daha iyi
                hizmet verebilmemiz adına Kullanıcı/&Uuml;ye&rsquo;lerin gerekli olduğu durumlarda a&ccedil;ık rızalarına istinaden, yasal d&uuml;zenlemeler uyarınca veri işleme amacı ve hukuki sebepler ile sınırlı olarak, bağlı ortaklıklarımıza ve/veya doğrudan/dolaylı yurti&ccedil;i/yurtdışı iştiraklerimize, Şirketimiz&rsquo;in faaliyetlerini y&uuml;r&uuml;tmek &uuml;zere ilgili s&ouml;zleşmeler kapsamında hizmet aldığı ya da işbirliği yaptığı yurt i&ccedil;i veya yurt
                dışındaki &uuml;&ccedil;&uuml;nc&uuml; kişilere aktarılabilecek, işbu Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası ile belirlenen ama&ccedil;lar ve kapsam dışında kullanılmamak kaydı ile KVKK&rsquo;nın &ouml;ng&ouml;rd&uuml;ğ&uuml; hallerde veri g&uuml;venliğine ilişkin</p>

            <p>tedbirler de alınarak; KVKK&rsquo;da sayılan şekillerde işlenebilecektir. Kullanıcı/&Uuml;ye&rsquo;ye ait Kişisel Veriler ile gezinme ve trafik bilgileri; g&uuml;venlik ve yasalar karşısındaki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;m&uuml;z&uuml; ifa etmek amacıyla (su&ccedil;la m&uuml;cadele, devlet ve kamu g&uuml;venliğinin tehdidi benzeri ve fakat bununla sınırlı olmamak &uuml;zere yasal veya idari olarak bildirim veya bilgi verme
                y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;m&uuml;z&uuml;n mevcut olduğu durumlarda) yasal olarak bu bilgileri talep etmeye yetkili olan ilgili kurum ve kuruluşlar ile paylaşılabilecektir.</p>

            <p><strong>Kişisel Verilerle İlgili Haklar</strong></p>

            <p>Kullanıcı/&Uuml;ye, KVVK Madde 11 gereği kendisine tanınan haklarla ilgili olarak aydınlatılmış kabul edilecek ve işbu Maddeye dayanarak; işlenen Kişisel Verileri&rsquo;nin neler olduğunu ve işlenip işlenmediğini, işleme amacı doğrultusunda kullanılıp kullanılmadığını &ouml;ğrenebilir, verileri işlenmişse bu hususta bilgi talep edebilir, yurt i&ccedil;i veya yurt dışında Kişisel Veriler&rsquo;in aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri &ouml;ğrenebilir, eksik veya yanlış
                işlenen Kişisel Veriler s&ouml;z konusu ise veya işlenen Kişisel Verileri&rsquo;nde herhangi bir değişiklik olması durumunda bu verilerin d&uuml;zeltilmesini, değiştirilmesini, g&uuml;ncellenmesini, KVKK&rsquo;nın 7. maddesinde &ouml;ng&ouml;r&uuml;len şartlar &ccedil;er&ccedil;evesinde silinmesini/yok edilmesini veya anonim hale getirilmesini, bu işlemlerin (eksik veya yanlış işlenen Kişisel Verileri s&ouml;z konusu ise, bu verilerin d&uuml;zeltilmesini veya KVKK&rsquo;nın 7.
                maddesinde &ouml;ng&ouml;r&uuml;len şartlar &ccedil;er&ccedil;evesinde silinmesini/yok edilmesini veya anonim hale getirilmesini) Kişisel Veriler&rsquo;in aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini talep edebilir; işlenen verilerin m&uuml;nhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhine bir sonucun ortaya &ccedil;ıkmasına itiraz edebilir ve Kişisel Veriler&rsquo;in kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması h&acirc;linde
                zararın giderilmesini talep edebilir. Kullanıcı/&Uuml;ye, bu kapsamdaki haklarını KVKK md. 13/f.1 uyarınca:</p>

            <p>&nbsp;</p>

            <p>&middot; Websitesinde yer alan e-posta adresine, Kullanıcı/&Uuml;ye&rsquo;ye ait g&uuml;venli elektronik imza ile imzalanmış bir e-posta g&ouml;ndermek(aynı zamanda Şirketimiz&rsquo;e hitaben yazılan</p>

            <p>&ldquo;word veya pdf.&rdquo; formatındaki bir dosya da g&uuml;venli e-imza ile imzalanarak g&ouml;nderilebilir);</p>

            <p>&middot; Şirketimizin kayıtlı e-posta adresine, Kullanıcı/&Uuml;ye&rsquo;ye ait g&uuml;venli elektronik imza ile imzalanmış bir e-posta g&ouml;ndermek veya</p>

            <p>&middot; Kargo ile ıslak imzasını taşıyan bir dilek&ccedil;e g&ouml;ndermek suretiyle Şirketimiz&rsquo;e başvuru yaparak, taleplerini iletebilir.</p>

            <p><strong>Kişisel Veri Saklama S&uuml;resi</strong></p>

            <p>Şirketimiz, KVKK uyarınca işlediği Kişisel Verileri yalnızca ilgili mevzuatta &ouml;ng&ouml;r&uuml;len veya mevzuatta bir s&uuml;re &ouml;ng&ouml;r&uuml;lmemiş ise kişisel veri işleme amacının gerektirdiği s&uuml;re kadar muhafaza eder. Tutulan veriler verinin işlenmesini gerektiren sebepler sona erdikten sonra silinir, yok edilir veya anonim hale getirilir. &Ouml;rnek vermek gerekirse 6563 Sayılı Elektronik Ticaretin D&uuml;zenlenmesi Hakkında Kanun ile buna bağlı y&ouml;netmelik
                uyarınca Kişisel Veriler&rsquo;in pazarlama veya tanıtım ama&ccedil;ları i&ccedil;in kullanılacağına ilişkin Kullanıcı/&Uuml;ye&rsquo;nin onayının geri alındığı durumlarda, Kişisel Veriler&rsquo;in kayıtları bu tarihten itibaren 1 yıl saklanır. Ticari elektronik iletinin i&ccedil;eriği ve g&ouml;nderiye ilişkin diğer her t&uuml;rl&uuml; kayıt ise gerektiğinde ilgili bakanlığa sunulmak &uuml;zere 3 yıl saklanır. 5651 Sayılı İnternet Ortamında Yapılan Yayınların D&uuml;zenlenmesi
                ve Bu Yayınlar Yoluyla İşlenen Su&ccedil;larla M&uuml;cadele Edilmesi Hakkında Kanun uyarınca ise işlediğimiz trafik verileri 2 yıl saklanır ve s&uuml;re bittikten sonra anonim hale getirilir. Her hal&uuml;karda Kişisel Veriler&rsquo;in birden fazla sebeple işlenmiş olması halinde, s&ouml;z konusu verinin işlenmesini gerektiren t&uuml;m nedenler ortadan kalktığında ilgili veri silinir, yok edilir veya anonim hale getirilir.</p>

            <p><strong>&Ccedil;erez (Cookie) Kullanımı</strong></p>

            <p>Şirketimiz, internet sitesi, ve mobilweb ziyaret&ccedil;ilerine daha iyi hizmet verebilmek amacıyla ve yasal y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; &ccedil;er&ccedil;evesinde, işbu &ldquo;Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası&rdquo; ile belirlenen ama&ccedil;lar ve kapsam dışında kullanılmamak kaydı ile Kullanıcı/&Uuml;ye gezinme bilgilerini işleyebilecektir.</p>

            <p>&Ccedil;erezler, Kullanıcı/&Uuml;ye&rsquo;nin s&ouml;z konusu internet sitesini ziyaret ettiğinde veya mobilweb &uuml;zerinden bağlandığında, Kullanıcı/&Uuml;ye&rsquo;nin internet tarayıcısı tarafından y&uuml;klenen ve bilgisayarı, cep telefonu veya tabletinde saklanan k&uuml;&ccedil;&uuml;k bilgi par&ccedil;acıklarını i&ccedil;eren metin dosyalarıdır. Şirketimiz, s&ouml;z konusu internet sitesi ve mobilweb bulunan &ccedil;erezleri g&uuml;nl&uuml;k dosyaları, boş gif dosyaları
                ve/veya &uuml;&ccedil;&uuml;nc&uuml; taraf kaynakları yoluyla topladığı bilgileri Kullanıcı/&Uuml;ye&rsquo;nin tercihleri ile ilgili bir &ouml;zet oluşturmak amacıyla depolar ve bu ama&ccedil;la 3. kişilerle paylaşabilir. Şirketimiz, s&ouml;z konusu internet sitesi ve mobilweb &uuml;zerinden, Kullanıcı/&Uuml;ye&rsquo;ye &ouml;zel tanıtım yapmak, promosyonlar ve pazarlama teklifleri sunmak, s&ouml;z konusu mecraların i&ccedil;eriğini Kullanıcı/&Uuml;ye&rsquo;ye g&ouml;re
                iyileştirmek ve/veya tercihlerini belirlemek amacıyla; Kullanıcı/&Uuml;ye&rsquo;nin s&ouml;z konusu mecralar &uuml;zerinde gezinme bilgilerini ve/veya bu mecralar &uuml;zerindeki kullanım ge&ccedil;mişini izleyebilmektedir. Buna ek olarak, &ccedil;evrimi&ccedil;i ve &ccedil;evrimdışı olarak toplanan bilgiler gibi farklı y&ouml;ntemlerle veya farklı zamanlarda bu mecralar &uuml;zerinde Kullanıcı/&Uuml;ye&rsquo;den toplanan bilgileri eşleştirebilir ve bu
                bilgileri &uuml;&ccedil;&uuml;nc&uuml; taraflar gibi başka kaynaklardan alınan bilgilerle birlikte kullanabilir. S&ouml;z konusu eşleştirme ve kullanma, yalnızca işbu Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası ile belirlenen ama&ccedil;lar ve kapsam dahilinde kalacak olup sadece bu ama&ccedil;lar dahilinde 3. kişilerle paylaşılabilecektir.</p>

            <p><strong>&Ccedil;erez &Ccedil;eşitleri</strong></p>

            <p>Şirketimiz, internet sitesi ve mobilweb oturum &ccedil;erezleri ve kalıcı &ccedil;erezler kullanmaktadır. Oturum kimliği &ccedil;erezi, Kullanıcı/&Uuml;ye tarayıcılarını kapattığında sona erer. Kalıcı &ccedil;erez ise Kullanıcı/&Uuml;ye&rsquo;ye ait sabit diskte uzun bir s&uuml;re kalır. Kullanıcı/&Uuml;ye, internet tarayıcılarının &quot;yardım&quot; dosyasında verilen talimatları izleyerek veya &quot;www.allaboutcookies.org&quot; veya &quot;www.youronlinechoices.eu&quot; adresini
                ziyaret ederek kalıcı &ccedil;erezleri kaldırabilir ve hem oturum &ccedil;erezlerini hem de kalıcı &ccedil;erezleri reddedebilir. Kullanıcı/&Uuml;ye kalıcı &ccedil;erezleri veya oturum &ccedil;erezlerini reddetmesi halinde, s&ouml;z konusu web sitesini ve mobilwebi kullanmaya devam edebilir, fakat s&ouml;z konusu mecraların t&uuml;m işlevlerine erişemeyebilir veya erişimi sınırlı olabilir.</p>

            <p><strong>1. &Ccedil;erezler Nasıl Kullanılmaktadır?</strong></p>

            <p>1.1.&Ccedil;erezler, Kullanıcı/&Uuml;ye&rsquo;nin yaptığı tercihleri hatırlamak ve web sitesi /mobilweb kullanımlarını kişiselleştirmek i&ccedil;in kullanılır. Bu kullanım:</p>

            <p>1.1.1 Kullanıcı/&Uuml;ye parolasını kaydeden ve internet sitesi/mobilweb oturumunun s&uuml;rekli a&ccedil;ık kalmasını sağlayan, b&ouml;ylece Kullanıcı/&Uuml;ye&rsquo;nin her ziyaretinde birden fazla kez parola girme zahmetinden kurtaran &ccedil;erezleri ve</p>

            <p>1.1.2 S&ouml;z konusu internet sitesine/mobilwebe daha sonraki ziyaretinde Kullanıcı/&Uuml;ye&rsquo;yi hatırlayan ve tanıyan &ccedil;erezleri</p>

            <p>1.2.Kullanıcı/&Uuml;ye&rsquo;nin s&ouml;z konusu internet sitesine/mobilwebenereden bağlandığı, internet sitesi/mobilweb &uuml;zerinde hangi i&ccedil;eriği g&ouml;r&uuml;nt&uuml;lediği ve ziyaretinin s&uuml;resi gibi web sitesini/mobilwebi uygulamayı nasıl kullandığının izlenmesi dahil olmak &uuml;zere; internet sitesini/mobilwebi nasıl kullandığını belirlemek i&ccedil;in kullanılır.</p>

            <p>1.3.Kullanıcı/&Uuml;ye&rsquo;ye ait ilgi alanlarına ve Kullanıcı/&Uuml;ye&rsquo;ye daha uygun i&ccedil;erik ve reklamları sunmak i&ccedil;in, diğer bir ifade ile hedeflenmiş reklam/tanıtım amacıyla kullanılır. Bu şekilde, internet sitesini ve mobilwebi kullandığında Kullanıcı/&Uuml;ye&rsquo;ye daha uygun i&ccedil;erikleri, kişiye &ouml;zel kampanya ve &uuml;r&uuml;nleri sunar ve daha &ouml;nceden istemediğini belirttiği i&ccedil;erik veya fırsatları bir daha
                Kullanıcı/&Uuml;ye&rsquo;nin dikkatine sunmaz. S&ouml;z konusu internet sitesi, &ccedil;erezler yoluyla elde edilen bilgileri topladığımız Kişisel Veriler&rsquo;le eşleştirir.</p>

            <p>2. S&ouml;z konusu internet sitesi &uuml;&ccedil;&uuml;nc&uuml; taraf &ccedil;erezlerini reklam ve yeniden hedefleme i&ccedil;in nasıl kullanmaktadır?</p>

            <p>S&ouml;z konusu internet sitesi &ccedil;erezleri ayrıca arama motorlarını, internet sitesi veya mobilwebi ve/veya internet sitesinin reklam verdiği internet sitelerini ziyaret ettiği vakit Kullanıcı/&Uuml;ye&rsquo;nin ilgisini &ccedil;ekebileceğini d&uuml;ş&uuml;nd&uuml;ğ&uuml; reklamları Kullanıcı/&Uuml;ye&rsquo;ye sunabilmek i&ccedil;in &ldquo;reklam teknolojisini&rdquo; devreye sokmak amacıyla kullanabilir. Reklam teknolojisi, Kullanıcı/&Uuml;ye&rsquo;ye &ouml;zel reklamlar
                sunabilmek i&ccedil;in web sitesine/mobilwebe ve internet sitesinin reklam verdiği web</p>

            <p>sitelerine/mobilwebe uygulama Kullanıcı/&Uuml;ye&rsquo;nin yaptığı &ouml;nceki ziyaretlerle ilgili bilgileri kullanır. Bu reklamları sunarken, internet sitesinin/mobilwebin Kullanıcı/&Uuml;ye&rsquo;yi tanıyabilmesi amacıyla Kullanıcı/&Uuml;ye&rsquo;nin tarayıcısına benzersiz bir &uuml;&ccedil;&uuml;nc&uuml; taraf &ccedil;erezi yerleştirilebilir. Kullanıcı/&Uuml;ye, internet tarayıcısının &quot;yardım&quot; dosyasında verilen talimatları izleyerek
                veya &quot;www.allaboutcookies.org&quot; veya &quot;www.youronlinechoices.eu&quot; adresini ziyaret ederek kalıcı &ccedil;erezleri kaldırabilir ve hem oturum &ccedil;erezlerini hem de kalıcı &ccedil;erezleri reddedebilir. Kullanıcı/&Uuml;ye, kalıcı &ccedil;erezleri veya oturum &ccedil;erezlerini reddederse, internet sitesini, mobilweb&rsquo;i veya mobil uygulamayı kullanmaya devam edebilir, fakat internet sitesinin, mobilweb ve mobil uygulamanın t&uuml;m işlevlerine
                erişemeyebilir veya erişimi sınırlı olabilir.</p>

            <p><strong>Kişisel Verilerin Korunmasına İlişkin &Ouml;nlemler</strong></p>

            <p>Kişisel Veriler&rsquo;in korunması Şirketimiz i&ccedil;in &ouml;nemli bir konudur. Kişisel Veriler&rsquo;e yetkisiz erişim veya bu bilgilerin kaybı, hatalı kullanımı, ifşa edilmesi, değiştirilmesi veya imha edilmesine karşı Kullanıcı/&Uuml;ye&rsquo;yi korumak i&ccedil;in gerekli teknik ve idari her t&uuml;rl&uuml; &ouml;nlemi almaktayız. Kişisel Veriler&rsquo;i işlerken genel kabul g&ouml;rm&uuml;ş g&uuml;venlik teknolojisi standartlarını kullanmaktayız.</p>

            <p>Kişisel Veriler&rsquo;i gizli tutmayı, gizliliğin sağlanması ve g&uuml;venliği i&ccedil;in gerekli teknik ve idari her t&uuml;rl&uuml; tedbiri almayı ve gerekli &ouml;zeni g&ouml;stermeyi taahh&uuml;t etmekteyiz. Gerekli bilgi g&uuml;venliği &ouml;nlemlerini almamıza karşın, Şirketimiz&rsquo;e ait internet sitesine, mobilwebe ve sisteme yapılan saldırılar sonucunda Kişisel Veriler&rsquo;in zarar g&ouml;rmesi veya &uuml;&ccedil;&uuml;nc&uuml; kişilerin eline ge&ccedil;mesi
                durumunda, bu durumu derhal sizlere ve Kişisel Verileri Koruma Kurulu&rsquo;na bildirir ve gerekli &ouml;nlemleri alırız.</p>

            <p><strong>Değişiklikler</strong></p>

            <p>İşbu Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası&rsquo;nda zaman zaman değişiklik yapılabilir. Bu değişiklikler, değiştirilmiş yeni Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası&rsquo;nın internet sitesinde/webmobilde yayınlanmasıyla</p>

            <p>birlikte derhal ge&ccedil;erlilik kazanır. Bu sebeple Kullanıcı/&Uuml;ye&rsquo;nin işbu metni periyodik olarak g&ouml;zden ge&ccedil;irmesini &ouml;neririz. Kullanıcı/&Uuml;ye, varsa Kişisel Bilgileri de i&ccedil;eren &uuml;yelik bilgilerini ve iletişim tercihlerini her zaman internet sitesinde yer alan iletişim bilgilerinden Şirketimiz&rsquo;e ulaşarak da iletebilir. Talepler en kısa s&uuml;rede değerlendirilerek uygulamaya alınacaktır.</p>

            <p><strong>Uygulanacak Hukuk, Yetkili Mahkeme ve İcra Daireleri</strong></p>

            <p>İşbu Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası, T&uuml;rkiye Cumhuriyeti kanunlarına tabidir. Gizlilik, Kişisel Verilerin Korunması ve &Ccedil;erez Politikası&rsquo;nın uygulanmasından doğabilecek her t&uuml;rl&uuml; uyuşmazlığın &ccedil;&ouml;z&uuml;m&uuml;nde İstanbul Merkez Mahkeme ve İcra Daireleri yetkilidir.</p>

            <p><strong>İletişim İzni</strong></p>
            <p>Kullanıcı/&Uuml;ye&rsquo;nin Şirketimiz ile paylaşmış olduğu Kişisel Veriler; satış-pazarlama amacıyla, &ccedil;erez politikamızda belirttiğimiz şekilde diğer şirketlerden topladığımız bilgiler ile birleştirerek hizmetlerimizin i&ccedil;erik ve tanıtımlarımızın geliştirilmesi ve kişileştirilmesi veya yeni hizmetler sunabilmek amacıyla ve her t&uuml;rl&uuml; iletişim mesajlarının g&ouml;nderilmesi amacıyla Şirketimiz kullanabilir. Bu veriler, aynı zamanda sadece
                Kullanıcı/&Uuml;ye&rsquo;ye sağlanacak hizmetlerin kusursuz sunulabilmesi, olası g&ouml;nderilerinizin sağlıklı şekilde teslim edilmesi, telefon, sms ve/veya e-posta yoluyla bildirimlerimizin zamanında ulaştırılabilmesi amacıyla, s&ouml;zleşme ilişkisi i&ccedil;inde olduğumuz, veri g&uuml;venliği konusundaki hassasiyetimizi paylaşan, ilgili mevzuat h&uuml;k&uuml;mlerine riayet eden yurt i&ccedil;inde ve dışında yerleşik 3. kişilerle, yalnızca ihtiya&ccedil; durumunda ve
                gerekli &ouml;l&ccedil;&uuml;de paylaşılacaktır. Kullanıcı/ &Uuml;ye, pazarlama ama&ccedil;lı bu tanıtımları almamak veya s&ouml;z konusu reklam-kişiselleştirme, yeniden hedefleme uygulamalarında yer almak istemediği takdirde, taleplerini her zaman internet sitesinde yer alan iletişim bilgilerinden Şirketimiz&rsquo;e ulaşarak da iletilebilecektir.</p>
        </>
    )
}

export function ModalM() {

    return (
        <>
            <p>İşbu Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni ile, 6698 Sayılı Kişisel Verilerin Korunması Kanunu&rsquo;nun (&ldquo;KVKK&rdquo;) 10. maddesi uyarınca, İstanbul Ticaret Sicilinde kayıtlı; Maslak Mah. Sanatkarlar Sk. Eclipse Tic. Blokları No:5 D Blok Kat:2/3 Sarıyer İstanbul adresinde mukim, NAOS İSTANBUL KOZMETİK SAN.VE TİC.LTD.ŞTİ (&ldquo;Şirket&rdquo;) olarak kişisel veri işleme faaliyetlerimiz hakkında sizleri bilgilendirmek ve aydınlatmak isteriz.</p>

            <p><strong>1. Veri Sorumlusu ve Temsilcisi</strong></p>

            <p>1.1. Tarafınıza ait kişisel veriler, Şirket tarafından veri sorumlusu sıfatıyla aşağıda a&ccedil;ıklanan ama&ccedil;lar doğrultusunda ve bunlarla sınırlı olarak, hukuka ve d&uuml;r&uuml;stl&uuml;k kurallarına uygun suretle işlenebilecek, kaydedebilecek, saklanabilecek, sınıflandırılabilecek, g&uuml;ncellenebilecek ve mevzuatın izin verdiği hallerde veyahut işlendikleri ama&ccedil;la sınırlı olarak &uuml;&ccedil;&uuml;nc&uuml; kişilere a&ccedil;ıklanabilecek/aktarabilecektir.</p>

            <p><strong>2. Kişisel Verilerinizin İşlenme Amacı</strong></p>

            <p>Kişisel verileriniz, KVKK&rsquo;da &ouml;ng&ouml;r&uuml;len temel ilkelere uygun olarak a&ccedil;ık rızanız olmasa dahi;</p>

            <p>o Mevzuattan kaynaklanan y&uuml;k&uuml;ml&uuml;l&uuml;klerin ve yasal bildirimlerin yerine getirilmesi, yasal takip s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi,</p>

            <p>o Satın almaya y&ouml;nelik bilgilerin toplanması ve satılan &uuml;r&uuml;n&uuml;n teslim amacıyla hazırlanarak g&ouml;nderilmesi,</p>

            <p>o Websitesinde s&ouml;z konusu ticari faaliyete y&ouml;nelik risk analizi ve y&ouml;netimi,</p>

            <p>o S&uuml;re&ccedil; denetim ve ş&uuml;pheli işlem araştırması s&uuml;re&ccedil;lerinin y&ouml;netimi,</p>

            <p>o Pazarlama amacı taşımayan; hizmet kalitesi ve m&uuml;şteri memnuniyetinin &ouml;l&ccedil;&uuml;mlenmesi, asistans hizmetlerinin değerlendirilmesi, şik&acirc;yet y&ouml;netimi, ilgili veriler doğrultusunda m&uuml;şterilerin segmente edilmesi, anket &ccedil;alışmalarının yapılması,</p>

            <p>o Gelen aramaların kayıt altına alınması, kayıtların hizmet kalitesinin arttırılması amacıyla kullanılması,</p>

            <p>o Hizmetin sunumuna y&ouml;nelik dış hizmet tedarik edilen firmalardan her t&uuml;rl&uuml; destek hizmetlerinin temin edilmesi,</p>

            <p>o Ger&ccedil;ek ve t&uuml;zel &uuml;&ccedil;&uuml;nc&uuml; kişilerden gelecek taleplere verilecek cevapların hazırlanması,</p>

            <p>A&ccedil;ık Rızanız bulunması halinde ise;</p>

            <p>Paylaşmış olduğunuz Kişisel Veriler; satış-pazarlama iletişimi ve genel bilgilendirmeler amacıyla, &ccedil;erez politikamızda belirttiğimiz şekilde diğer şirketlerden topladığımız bilgiler ile birleştirerek hizmetlerimizin i&ccedil;erik ve tanıtımlarımızın geliştirilmesi ve kişileştirilmesi veya yeni hizmetler sunabilmek amacıyla ve her t&uuml;rl&uuml; iletişim mesajlarının g&ouml;nderilmesi amacıyla kullanabilir.</p>

            <p>İlgili kişi, pazarlama ama&ccedil;lı bu tanıtımları almamak veya s&ouml;z konusu reklam-kişiselleştirme, yeniden hedefleme uygulamalarında yer almak istemediği takdirde, taleplerini her zaman internet sitesinde yer alan iletişim bilgilerinden ulaşarak da iletebilecektir.</p>

            <p><strong>3. Kişisel Verilerinizin Aktarılması</strong></p>

            <p>Kişisel Verileriniz, aynı zamanda sadece ilgili kişiye sağlanacak hizmetlerin daha iyi sunulabilmesi, daha g&uuml;venli bir şekilde saklanması, korunması ve olası g&ouml;nderilerinizin sağlıklı şekilde teslim edilmesi, telefon, sms ve/veya e-posta yoluyla bildirimlerimizin zamanında ulaştırılabilmesi amacıyla, s&ouml;zleşme ilişkisi i&ccedil;inde olduğumuz, veri g&uuml;venliği konusundaki hassasiyetimizi paylaşan, ilgili mevzuat h&uuml;k&uuml;mlerine riayet eden; yerli ya da
                yabancı &uuml;lkelerde yerleşik 3. kişilerle, yalnızca ihtiya&ccedil; durumunda ve gerekli &ouml;l&ccedil;&uuml;de paylaşılacaktır.</p>

            <p><strong>4. Yurtdışına Veri Aktarımı</strong></p>

            <p>Şirket tarafından, hukuka uygun olarak işlenen kişisel verileriniz, a&ccedil;ık rızanız doğrultusunda veya KVKK m. 5/2 ile KVKK m. 6/3 h&uuml;k&uuml;mlerinde &ouml;ng&ouml;r&uuml;len durumların varlığı halinde a&ccedil;ık rızanız temin edilmeksizin, Kişisel Verileri Koruma Kurulu (&ldquo;Kurul&rdquo;) tarafından yeterli korumaya sahip olduğu tespit ve ilan edilen yabancı &uuml;lkelerde mukim kişi veyahut kuruluşlara veya kişisel verilerin aktarıldığı &uuml;lkedeki veri
                sorumlularının yeterli bir korumayı yazılı olarak taahh&uuml;t ettiği ve kurulun izninin temin edilebildiği hallerle sınırlı olmak kaydıyla Kurulca tespit ve ilan edilenlerin dışındaki &uuml;lkelere aktarılabilecektir.</p>

            <p><strong>5. Kişisel Verilerinizin Toplanma Y&ouml;ntemi ve Hukuki Sebebi</strong></p>

            <p>Kişisel verileriniz, faaliyetlerimizi y&uuml;r&uuml;tmek amacıyla Şirket tarafından form, e-posta, telefon, sms, web sitemiz, mobil uygulamamız, kargo, yazışmalar, saha &ccedil;alışmaları, sosyal medya, 3. kişiler, posta, web ara y&uuml;z&uuml;, kamera kaydı, asistans firmalar ve sair kanallar vasıtasıyla ve yukarıda belirtilen hukuki sebeplere dayanarak toplanmaktadır. Bu &ccedil;er&ccedil;evede kişisel verileriniz, KVKK m. 4/2 h&uuml;km&uuml;nde &ouml;ng&ouml;r&uuml;len ilkeler
                ışığında a&ccedil;ık rıza temini suretiyle veya KVKK m. 5/2 ve KVKK m. 6/3 h&uuml;k&uuml;mlerinde &ouml;ng&ouml;r&uuml;len durumların varlığı halinde, a&ccedil;ık rıza temin edilmeksizin işlenebilmekte ve aktarılabilmektedir.</p>

            <p><strong>6. KVKK&rsquo;nın 11. Maddesi Uyarınca Sahip Olduğunuz Haklar</strong></p>

            <p>Şirket tarafından KVKK m. 11 kapsamında tarafınızca yapılacak başvuruların ivedilikle, etkin ve kapsamlı bir şekilde değerlendirilebilmesi ve &ccedil;&ouml;z&uuml;mlenebilmesi adına internet sitemizde yer alan &ldquo;İletişim&rdquo; sayfamızda bildirilen adresimize ıslak imzalı olarak iadeli taahh&uuml;tl&uuml; posta yolu ile iletebilirsiniz. Şirket, niteliğine g&ouml;re, talebinizi m&uuml;mk&uuml;n olan en kısa s&uuml;rede ve her hal&uuml;karda en ge&ccedil; otuz (30) g&uuml;n
                i&ccedil;inde &uuml;cretsiz olarak sonu&ccedil;landıracaktır. Ancak, başvurunuzun incelenmesi ve sonu&ccedil;landırılmasına y&ouml;nelik olarak ger&ccedil;ekleştirilecek işlemlerin ayrıca bir maliyeti gerektirmesi halinde, Kurul tarafından belirlenen tarifedeki &uuml;cretler tarafınıza fatura edilecektir.</p>

            <p><strong>Yukarıda anılan madde h&uuml;km&uuml; uyarınca sahip olduğunuz haklar aşağıdaki gibidir:</strong></p>

            <p>o Kişisel verinizin Şirket tarafından işlenip işlenmediğini &ouml;ğrenme,</p>

            <p>o Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</p>

            <p>o Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme,</p>

            <p>o Yurt i&ccedil;inde veya yurt dışında kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme, Kişisel verilerin eksik veya yanlış işlenmiş olması h&acirc;linde bunların d&uuml;zeltilmesini isteme,</p>

            <p>o KVKK m. 7&rsquo;de &ouml;ng&ouml;r&uuml;len şartlar &ccedil;er&ccedil;evesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</p>

            <p>o KVKK m. 11 (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme</p>

            <p>o Kişisel verilerinizin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonu&ccedil; doğurması durumunda itiraz etme,</p>

            <p>o Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi sebebiyle herhangi bir zarara uğramanız h&acirc;linde zararın giderilmesini talep etme.</p>

            <p><strong>7. Kişisel Veri Saklama S&uuml;resi</strong></p>

            <p>Şirketimiz, KVKK uyarınca işlediği Kişisel Verileri yalnızca ilgili mevzuatta &ouml;ng&ouml;r&uuml;len veya mevzuatta bir s&uuml;re &ouml;ng&ouml;r&uuml;lmemiş ise kişisel veri işleme amacının gerektirdiği s&uuml;re kadar muhafaza eder. Tutulan veriler verinin işlenmesini gerektiren sebepler sona erdikten sonra silinir, yok edilir veya anonim hale getirilir. &Ouml;rnek vermek gerekirse 6563 Sayılı Elektronik Ticaretin D&uuml;zenlenmesi Hakkında Kanun ile buna bağlı y&ouml;netmelik
                uyarınca Kişisel Veriler&rsquo;in pazarlama veya tanıtım ama&ccedil;ları i&ccedil;in kullanılacağına ilişkin ilgili Kişinin onayının geri alındığı durumlarda, Kişisel Veriler&rsquo;in kayıtları bu tarihten itibaren 1 yıl saklanır. Ticari elektronik iletinin i&ccedil;eriği ve g&ouml;nderiye ilişkin diğer her t&uuml;rl&uuml; kayıt ise gerektiğinde ilgili bakanlığa sunulmak &uuml;zere 3 yıl saklanır. 5651 Sayılı İnternet Ortamında Yapılan Yayınların D&uuml;zenlenmesi ve Bu Yayınlar
                Yoluyla İşlenen Su&ccedil;larla M&uuml;cadele Edilmesi Hakkında Kanun uyarınca ise işlediğimiz trafik verileri 2 yıl saklanır ve s&uuml;re bittikten sonra anonim hale getirilir. Her hal&uuml;karda Kişisel Veriler&rsquo;in birden fazla sebeple işlenmiş olması halinde, s&ouml;z konusu verinin işlenmesini gerektiren t&uuml;m nedenler ortadan kalktığında ilgili veri silinir, yok edilir veya anonim hale getirilir.</p>

            <p><strong>8. Bilgilerinizin G&uuml;ncellenmesi Talebi</strong></p>

            <p>Şirket nezdinde işlenen kişisel verilerinizin doğru ve g&uuml;ncel olması gerekmektedir. Bu nedenle, kişisel veri niteliğindeki bilgilerinizde herhangi bir değişiklik meydana gelmesi halinde, bu hususu internet sitemizde yer alan &ldquo;İletişim&rdquo; sayfamızda bildirilen e-mail adresimize, &Uuml;yelik esnasında kullandığınız iletişim e-posta adresinizden g&ouml;ndereceğiniz bir e-posta yolu ile bildirebilirsiniz.</p>

            <p><strong>NAOS İSTANBUL KOZMETİK SAN.VE TİC.LTD.ŞTİ</strong></p>

        </>
    );
}
