import {Checkbox, Form, message, Spin} from "antd";
import React from "react";
import {withRouter} from 'react-router-dom';
import Api from "../config/Api";
import {errorMessage, pageChange} from "../helpers/GeneralHelpers";
import validator from 'validator';

let self = null;

class InfoUser extends React.Component {
    constructor(props) {
        super(props);

        self = this;

        this.state = {
            spinning: false,
            smsCode: self.props.match.params.smsCode,
            info: {}
        }

    }

    componentDidMount() {
        const {smsCode} = self.state;
        if (validator.isUUID(smsCode)) {
            self.getcustomerbyotpcode();
        } else {
            pageChange(self,'/');
        }
    }

    getcustomerbyotpcode() {
        const {smsCode} = self.state;
        self.setState({spinning: true});
        Api.getcustomerbyotpcode(smsCode).then(function (response) {
            self.setState({
                spinning: false,
                info: response.data
            });
        }).catch((error) => {
            this.setState({spinning: false}, () => {
                const message2 = errorMessage(error);
                message.error(message2);
            });
        });
    }


    render() {
        const {spinning, info} = self.state;

        return (
            <>
                <Spin size={'large'} spinning={spinning}>
                    <div className={'logo'}>
                        <img src={require('../../src/images/logo-white.png')}/>
                    </div>
                    {
                        info && (
                            <div className={'form1'}>
                                <div className={'box'}>
                                    <div className="box-header b-b">
                                        <h6>NAOS İzin Formu</h6>
                                    </div>
                                    <div className={'box-body'}>
                                        <div className={'form-horizontal'}>
                                            <div className="form-group row row-sm">
                                                <div className="col-sm-4">Adı Soyadı</div>
                                                <div className="col-sm-8"><span className="text-left block">{info.name + ' ' + info.surname}</span></div>
                                            </div>
                                            <div className="form-group row row-sm">
                                                <div className="col-sm-4">Telefon Numarası</div>
                                                <div className="col-sm-8"><span className="text-left block">{info.mobileNumber}</span></div>
                                            </div>
                                            <div className="form-group row row-sm">
                                                <div className="col-sm-4">E-Posta Adresi</div>
                                                <div className="col-sm-8"><span className="text-left block">{info.email}</span></div>
                                            </div>
                                        </div>

                                        <div className={'text-center dark'}>
                                            <h4 className="p-a text-white">Doğrulama Kodunuz :<br/><br/>
                                                <span className={'ax'}>{info.smsCode}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Spin>
            </>
        )
    }
}

export default withRouter(InfoUser);
