import React, {Component} from 'react'
import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';
import {googleAnalyticsID} from "./config/ConfigGeneral";

let self = null;

class App extends Component {

    constructor(props) {
        super(props);
        self = this;
        ReactGA.initialize(googleAnalyticsID, {debug: false, titleCase: false});
        ReactGA.pageview(window.location.pathname + window.location.search, document.title);
        this.unlisten = this.props.history.listen((location, action) => {
            //500 ms içinde google bildirim yolla.
            setTimeout(function () {
                ReactGA.pageview(window.location.pathname + window.location.search, document.title);
            }, 500);
        });
    }


    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(App);
